<template>
  <v-container>
        <v-row>
          <v-col>
            <v-row>
              <v-col >
                <div class="d-flex">
                  <v-card flat>
                    <v-card-title>
                      <v-icon color="primary">mdi-rocket-launch</v-icon>
                    </v-card-title>
                    <v-card-title class="mt-n5">
                      Continuously Active
                    </v-card-title>
                    <v-card-text class="mt-n3">
                      <div>
                        We are constantly adding problems and improving functionality.
                      </div>
                    </v-card-text>
                  </v-card>
                </div>

                 <div class="d-flex">
                  <v-card flat>
                    <v-card-title>
                      <v-icon color="primary">mdi-square</v-icon>
                    </v-card-title>
                    <v-card-title class="mt-n5">
                      Open Project
                    </v-card-title>
                    <v-card-text class="mt-n3">
                      <div>
                        haq.ai is an open project, meaning that we value collaboration and feedback!
                      </div>
                    </v-card-text>
                  </v-card>

                </div>
              </v-col>
              <v-col>
                <div class="d-flex">
                  <v-card flat>
                    <v-card-title>
                      <v-icon color="primary">mdi-credit-card</v-icon>
                    </v-card-title>
                    <v-card-title class="mt-n5">
                      Completely Free
                    </v-card-title>
                    <v-card-text class="mt-n3">
                      <div>
                        All our features are free as we rely only on donations to fund the site. 
                      </div>
                    </v-card-text>
                  </v-card>
                </div>

                <div class="d-flex">
                  <v-card flat>
                    <v-card-title>
                      <v-icon color="primary">mdi-account</v-icon>
                    </v-card-title>
                    <v-card-title class="mt-n5">
                      Global Community
                    </v-card-title>
                    <v-card-text class="mt-n3">
                      <div>
                        Join hundreds of other haqers in our Discord community to learn more!
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <div class="d-flex justify-center">
              <v-card
                max-width="408px"
                elevetion="3"
                class="rounded-lg"
                :color="theme ? '#ADB3CD' : ''"
                light
              >
                <v-img
                  class="ma-2 rounded-lg"
                  :src="theme ? require('@/assets/images/insight-dark.svg') : require('@/assets/images/insight-light.svg')"
                ></v-img>

                <v-card-title class="mt-3">
                  <span>Business enquiries </span>
                </v-card-title>
                <v-card-text class="mt-n4">
                  <span>If you feel like either we or you can bring something to our respective tables, feel free to contact adam@haq.ai for business enquires.</span>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';

export default {
  name: 'services',
  mixins: [colorMxn],
  data() {
    return {
    }
  }
}
</script>